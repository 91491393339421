.color {
    @each $theme in $themes {
        &-#{$theme} {
            --current-color-main: var(--color-#{$theme});
            --current-color-light: var(--color-#{$theme}-light);
        }
    }

    &-default {
        --current-color-main: var(--color-grey-2);
        --current-color-light: var(--color-grey-6);
    }

    &-danger {
        --current-color-main: var(--color-ruby);
        --current-color-light: var(--color-ruby-light);
    }

    &-success {
        --current-color-main: var(--color-success);
        --current-color-light: var(--color-success-light);
    }

    &-warning {
        --current-color-main: var(--color-citrine);
        --current-color-light: var(--color-citrine-light);
    }
}

.text-color {
    color: var(--current-color-main) !important;

    &-light {
        color: var(--current-color-light) !important;
    }

    &-grey {
        color: var(--color-grey-3) !important;
    }
}

.bg-color {
    background: var(--current-color-main);

    &-light {
        background: var(--current-color-light);
    }
}

.bg-form {
    background: var(--color-grey-6);
}

.border {
    &-main {
        border: 2px solid var(--current-color-main);
    }
    &-grey {
        border: 1px solid var(--color-grey-5);
    }
}
