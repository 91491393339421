@import 'flex';
@import 'cursor';
@import 'font';
@import 'display';
@import 'margin';

.cover {
    width: 100px;

    &-75 {
        width: 75px;
    }

    &-height-100 {
        max-height: 100vh;
    }
}

.br-10 {
    border-radius: 10px;
}

.gap {
    &-15 {
        gap: 15px;
    }

    &-20 {
        gap: 20px;
    }

    &-50 {
        gap: 50px;
    }
}

.border-none {
    border: none!important;
}

.position {
    &-relative {
        position: relative;
    }

    &-absolute {
        position: absolute;
    }
}
