@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import 'styles/main';

body {
    margin: 0;

    * {
        font-family: Roboto, 'Helvetica Neue', sans-serif;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: var(--weight-semi-bold);
}

p {
    font-size: 14px;
}
