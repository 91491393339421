.flex {
    display: flex;

    &-column {
        flex-direction: column;
    }

    &-space-between {
        justify-content: space-between;
    }

    &-space-around {
        justify-content: space-around;
    }

    &-justify-center {
        justify-content: center;
    }

    &-justify-end {
        justify-content: flex-end;
    }

    &-align-center {
        align-items: center;
    }
}
