.text {
    &-upper {
        text-transform: uppercase;
    }

    &-bold {
        font-weight: 500;
    }

    &-small {
        font-size: 0.75rem;
    }
}
