.weight {
    &-light {
        font-weight: var(--weight-light);
    }

    &-semi-bold {
        font-weight: var(--weight-semi-bold);
    }

    &-bold {
        font-weight: var(--weight-bold);
    }
}
