#table {
    font-family: 'Montserrat', sans-serif;
    border-collapse: separate;
    background: #ffffff;
    padding: 0;
    margin-top: 25px;
    border-radius: 10px;

    thead {
        position: sticky;
        top: 83px;
        z-index: 99;
        border-radius: 10px;

        th {
            background: #fff;
            border-bottom: 2px solid var(--current-color-light);

            &:first-child {
                border-top-left-radius: 10px;
            }
            &:last-child {
                border-top-right-radius: 10px;
            }
        }
    }
}
