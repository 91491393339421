.form {
    margin-bottom: 15px;

    &__group {
        width: 100%;

        &:not(.form__group--select) {
            margin-top: 10px;
        }

        &__label {
            display: inline-block;
            font-size: 14px;
            font-weight: var(--weight-semi-bold);
            margin: 10px 0;
        }

        &__input {
            width: 100%;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 5px;
            outline: none;
            border: 2px solid var(--color-grey-5);
            transition: border ease 0.4s;

            &:focus {
                border: 2px solid var(--color-sapphire);
            }
        }

        &__select {
            margin-top: 10px;
            font-size: 14px;
            padding-bottom: 0;

            &:not(.ng-select-multiple) {
                .ng-value {
                    position: absolute;
                    bottom: 9px;
                    color: var(--color-grey-3);
                }
            }

            &.ng-select-multiple {
                .ng-value {
                    background: var(--color-sapphire) !important;
                }
            }

            &:not(.ng-select-container) {
                .ng-select-container:not(.ng-has-value) {
                    .ng-placeholder {
                        bottom: 9px;
                    }
                }
            }

            .ng-placeholder {
                font-weight: var(--weight-semi-bold);
                color: var(--color-grey-1) !important;
            }

            &.ng-select-focused {
                .ng-select-container:after {
                    border: 1px solid var(--color-sapphire);
                }
            }

            .ng-select-container {
                &:after {
                    border: 1px solid var(--color-grey-5);
                }

                .ng-value-container {
                    border-top-width: 16px;
                }
            }
        }
    }
}
