$themes: 'jade', 'sapphire', 'amethyst', 'ruby', 'citrine';

:root {
    /* Colors */
    --color-grey-1: #333333;
    --color-grey-2: #4b4b4b;
    --color-grey-3: #828282;
    --color-grey-4: #bdbdbd;
    --color-grey-5: #e0e0e0;
    --color-grey-6: #f8f8f8;

    --color-jade: #42877b;
    --color-jade-light: #cdf5ee;
    --color-sapphire: #306da7;
    --color-sapphire-light: #c1dbf3;
    --color-amethyst: #8830a7;
    --color-amethyst-light: #e9c9f4;
    --color-ruby: #a73030;
    --color-ruby-light: #f5c9c9;
    --color-citrine: #c2a331;
    --color-citrine-light: #f5ecc9;

    --color-success: #24c045;
    --color-success-light: #c7eacf;

    /* Fonts */
    --weight-bold: 600;
    --weight-semi-bold: 500;
    --weight-normal: 400;
    --weight-semi-light: 300;
    --weight-light: 200;
}
